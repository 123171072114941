import React, { FC, Suspense } from 'react';

import './external/shared/styles/bootstrap';
import { ScreenSpinner, ShowScreenSpinner } from './external/shared/spinner/ScreenSpinner';
import { ErrorBoundary } from './external/shared/error-handling/ErrorBoundary';
import { ErrorModal } from './external/shared/error-handling/ErrorModal';
import { errorModalStore } from './external/shared/error-handling/ErrorModalStore';
import { ExtractedStylesRenderer } from './external/shared/styles/ExtractedStylesRenderer';

const Enrollment = React.lazy(() => import('./bootstrapEnrollment' /* webpackChunkName: 'bootstrapEnrollment' */));

export const App: FC = () => {
  return (
    <>
      <ExtractedStylesRenderer />
      <Suspense fallback={<ShowScreenSpinner />}>
        <ErrorBoundary>
          <Enrollment />
        </ErrorBoundary>
      </Suspense>
      <Suspense fallback={errorModalStore.errorId && <ShowScreenSpinner />}>
        <ErrorModal store={errorModalStore} />
      </Suspense>
      <ScreenSpinner />
    </>
  );
};
