import { observer } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { Fade } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import styles from './ScreenSpinner.module.scss';
import { screenSpinnerStore } from './ScreenSpinnerStore';

export const ShowScreenSpinner: FC = () => {
  useEffect(() => {
    screenSpinnerStore.spin();
    return () => screenSpinnerStore.unspin();
  });
  return null;
};

export const ScreenSpinner = observer(function ScreenSpinner() {
  return (
    <Fade in={screenSpinnerStore.showSpinner} mountOnEnter unmountOnExit appear>
      <div className={styles.fullScreen}>
        <Spinner animation="border" variant="dark" />
      </div>
    </Fade>
  );
});
