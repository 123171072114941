import { makeAutoObservable } from 'mobx';

import { configureMobx } from '../utils/configureMobx';

export class ScreenSpinnerStore {
  constructor() {
    configureMobx();
    makeAutoObservable(this);
  }

  private spinCounter = 0;

  get showSpinner() {
    return this.spinCounter > 0;
  }

  spin() {
    this.spinCounter++;
  }

  unspin() {
    this.spinCounter--;
  }
}

export const screenSpinnerStore = new ScreenSpinnerStore();
