import { types } from 'mobx-state-tree';

export const FoundNamespace = types
  .model({
    ns: types.string,
    label: types.string,
    value: types.string
  })
  .views(self => ({
    get namespacedLabel() {
      return `${self.ns}:${self.label}`;
    }
  }));
