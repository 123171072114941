import { destroy, flow, Instance, types } from 'mobx-state-tree';
import i18n from 'i18next';
import { MissingTranslation } from './MissingTranslation';
import { enrollmentResource } from '../../http/EnrollmentResource';

export const MissingLanguageTranslations = types
  .model({
    language: types.string,
    translations: types.optional(types.array(MissingTranslation), [])
  })
  .views(self => ({
    get haveLabelsToCopy() {
      return self.translations.some(x => x.copiedTranslation);
    }
  }))
  .actions(self => ({
    saveCopy: flow(function* saveCopy() {
      const translations = self.translations!.filter(x => x.copiedTranslation !== undefined);
      const translationForms = translations.map(x => ({
        fromNamespace: x.copiedTranslation!.ns,
        fromLabel: x.copiedTranslation!.label,
        toLabel: x.copyLabelField.value
      }));

      yield enrollmentResource.post('/missing-translations/copy-translation', {
        toNamesPace: 'enrollment2',
        translations: translationForms
      });
      // eslint-disable-next-line import/no-named-as-default-member
      yield i18n.reloadResources();
      // eslint-disable-next-line import/no-named-as-default-member
      yield i18n.changeLanguage(i18n.language);
      translations.forEach(destroy);
    }),
    addTranslation(namespace: string, label: string, value: string) {
      if (self.translations.some(x => x.label === label && x.namespace === namespace)) {
        return;
      }
      const newTranslation = MissingTranslation.create({
        language: self.language,
        namespace,
        label,
        value
      });
      newTranslation.findTranslationInOtherNamespaces();
      self.translations.push(newTranslation);
    },
    removeTranslation(translation: any) {
      destroy(translation);
    }
  }))
  .named('MissingLanguageTranslations');

export type MissingLanguageTranslations = Instance<typeof MissingLanguageTranslations>;
