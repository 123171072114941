import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { onMissingTranslation } from './missing-translations/MissingTranslationStore';
import { contentfulLabelsBackend } from '../../external/shared/localization/ContentfulLabelsBackend';
import { languageDetector } from '../../external/shared/localization/languageDetector';
import { Namespace } from '../../external/shared/localization/Namespace';

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(contentfulLabelsBackend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    returnNull: true,
    returnEmptyString: true,
    ns: Namespace.Enrollment2,
    defaultNS: Namespace.Enrollment2,
    load: 'currentOnly',
    debug: process.env.NODE_ENV === 'development',
    saveMissing: process.env.NODE_ENV === 'development',
    missingKeyHandler: process.env.NODE_ENV === 'development' && onMissingTranslation,
    interpolation: {
      escapeValue: false
    }
  });

(window as any).i18n = i18n;
