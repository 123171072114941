import { observable, action } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';

export const styleNodes = observable(window.yl_enrollment_2_style_nodes ?? []);

window.yl_enrollment_2_style_nodes = new Proxy(styleNodes, {
  get(target, property) {
    if (property === 'push') {
      return action(target.push);
    }
    return target[property as keyof typeof target];
  }
});

const Stylesheet: FC<{ element: HTMLLinkElement | HTMLStyleElement }> = ({ element }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mutationCounter, setMutationCounter] = useState(0);
  const [observer] = useState(() => {
    const observer = new MutationObserver(() => setMutationCounter(x => x + 1));
    observer.observe(element, { attributes: true, childList: true, subtree: true });
    return observer;
  });
  useEffect(() => {
    return () => observer.disconnect();
  }, [observer]);

  const attributes: Record<string, string> = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const attribute of element.attributes) {
    attributes[attribute.name] = attribute.value;
  }

  return element instanceof HTMLLinkElement ? (
    <link {...attributes} rel="stylesheet" />
  ) : (
    <style {...attributes}>{element.innerHTML}</style>
  );
};

export const ExtractedStylesRenderer = observer(function StylesRenderer() {
  return (
    <>
      {styleNodes.map((x, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Stylesheet key={index} element={x} />
      ))}
    </>
  );
});
