import { types } from 'mobx-state-tree';
import { MissingLanguageTranslations } from './MissingLanguageTranslations';

export const MissingTranslationStore = types
  .model({
    languages: types.optional(types.array(MissingLanguageTranslations), []),
    showFixer: types.optional(types.boolean, false)
  })
  .actions(self => ({
    addLanguage(newLanguage: MissingLanguageTranslations) {
      self.languages.push(newLanguage);
    },
    toggleFixer() {
      self.showFixer = !self.showFixer;
    }
  }))
  .views(self => ({
    getLanguageTranslations(language: string) {
      const languageTranslations = self.languages.find(x => x.language === language);
      if (!languageTranslations) {
        const newLanguage = MissingLanguageTranslations.create({ language, translations: [] });
        self.addLanguage(newLanguage);
        return newLanguage;
      }
      return languageTranslations;
    }
  }))
  .named('MissingTranslationStore');

export const missingTranslations = MissingTranslationStore.create();

export function onMissingTranslation(
  languages: readonly string[],
  namespace: string,
  key: string,
  fallbackValue: string
): void {
  // eslint-disable-next-line no-restricted-syntax
  for (const language of languages) {
    const missingForLanguage = missingTranslations.getLanguageTranslations(language);
    missingForLanguage.addTranslation(namespace, key, fallbackValue);
  }
}
