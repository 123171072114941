import axios, { AxiosInstance } from 'axios';
import { BackendModule, InitOptions, ReadCallback, Services } from 'i18next';

import { ContentfulLabelsClient, MissingTranslationClient } from '../api/EnrollmentClient.generated';

export class ContentfulLabelsBackend implements BackendModule<any> {
  readonly type = 'backend';
  private services: Services | undefined = undefined;
  private backendOptions: any | undefined = undefined;
  private i18nextOptions: InitOptions | undefined = undefined;

  private readonly contentfulLabelsClient;
  private readonly missingTranslationsClient;

  constructor(baseUrl?: string, instance?: AxiosInstance) {
    this.contentfulLabelsClient = new ContentfulLabelsClient(baseUrl, instance);
    this.missingTranslationsClient = new MissingTranslationClient(baseUrl, instance);
  }

  init(services: Services, backendOptions: any, i18nextOptions: InitOptions) {
    this.services = services;
    this.backendOptions = backendOptions;
    this.i18nextOptions = i18nextOptions;
  }

  async read(language: string, namespace: string, callback: ReadCallback) {
    try {
      const data = await this.contentfulLabelsClient.getTranslations(namespace, language);
      callback(undefined, data);
    } catch (error) {
      callback(error as any, undefined);
    }
  }

  async create(languages: readonly string[], namespace: string, key: string, fallbackValue: string) {
    // eslint-disable-next-line no-restricted-syntax
    for (const language of languages) {
      await this.missingTranslationsClient.createMissingTranslation(language, namespace, {
        [key]: fallbackValue
      });
    }
  }
}

export const contentfulLabelsBackend = new ContentfulLabelsBackend('', axios.create({ baseURL: YL_BASE_URL }));
