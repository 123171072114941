import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ErrorModalStore } from './ErrorModalStore';

interface Props {
  store: ErrorModalStore;
  container?: React.RefObject<HTMLElement>;
}

export const ErrorModal = observer<Props>(function ErrorModal({ container, store }) {
  const { t } = useTranslation();

  return (
    <Modal container={container} show={!!store.errorId} onHide={() => store.close()} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t('ErrorDialog_DefaultTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('ErrorDialogMessage')}</p>
        {store.shortErrorId && (
          <p>
            {t('ErrorDialog_ErrorCode')}: <span>{store.shortErrorId}</span>
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => store.close()}>
          {t('ErrorDialog_DismissButtonLabel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});
