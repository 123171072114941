/* eslint-disable import/no-import-module-exports */
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import './infrastructure/localization/i18n';

function renderApp(Component: typeof App) {
  ReactDOM.render(<Component />, document.getElementById('root'));
}

renderApp(App);

if (module.hot) {
  module.hot.accept('./App', async () => {
    const { App: NextApp } = await import('./App');
    renderApp(NextApp);
  });
}
