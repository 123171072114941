export enum Namespace {
  Enrollment2 = 'enrollment2',
  LinkBuilder = 'link-builder',
  SocialLink = 'socialLink',
  Enrollment = 'enrollment',
  Default = 'default',
  StarterKits = 'starterkits',
  Global = 'global',
  Address = 'address',
  ShippingAlert = 'shippingAlert',
  EnrollLabels = 'enrollLabels',
  ProductInfo = 'productInfo',
  Referrers = 'referrers',
  Validation = 'validation',
  Authentication = 'authentication',
  PaymentSummary = 'paymentSummary',
  ErrorDialog = 'errorDialog',
  UplineLeader = 'uplineLeader',
  TaxInformation = 'taxInformation',
  PickupPointSelectDialog = 'pickupPointSelectDialog'
}
