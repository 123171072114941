/* eslint-disable import/no-named-as-default-member */
import i18n from 'i18next';
import { FieldState } from 'formstate';
import { clone, flow, getParentOfType, Instance, types } from 'mobx-state-tree';

import { FoundNamespace } from './FoundNamespace';
import { enrollmentResource } from '../../http/EnrollmentResource';
import { MissingLanguageTranslations } from './MissingLanguageTranslations';
import { Namespace } from '../../../external/shared/localization/Namespace';

const namespaces = [
  Namespace.Enrollment,
  Namespace.Default,
  Namespace.StarterKits,
  Namespace.Global,
  Namespace.Address,
  Namespace.ShippingAlert,
  Namespace.EnrollLabels,
  Namespace.ProductInfo,
  Namespace.Referrers,
  Namespace.Validation,
  Namespace.Authentication,
  Namespace.PaymentSummary,
  Namespace.ErrorDialog,
  Namespace.UplineLeader,
  Namespace.TaxInformation,
  Namespace.PickupPointSelectDialog
];

export const MissingTranslation = types
  .model({
    label: types.string,
    value: types.string,
    namespace: types.string,
    language: types.string,
    otherNamespaces: types.optional(types.array(FoundNamespace), []),
    copiedTranslation: types.maybe(FoundNamespace)
  })
  .views(self => ({
    get inputField() {
      return new FieldState('');
    },
    get copyLabelField() {
      if (self.copiedTranslation) {
        return new FieldState(self.copiedTranslation.label);
      }
      return new FieldState('');
    }
  }))
  .actions(self => ({
    saveMissing: flow(function* saveMissing() {
      if (self.inputField.value.length === 0) {
        return;
      }
      const data = {} as any;
      data[self.label] = self.inputField.value;
      yield enrollmentResource.put(`/missing-translations/${self.namespace}/${self.language}`, data);
      yield i18n.reloadResources();
      yield i18n.changeLanguage(i18n.language);
      const parent = getParentOfType(self, MissingLanguageTranslations);
      parent.removeTranslation(self);
    })
  }))
  .actions(self => ({
    save: flow(function* save() {
      if (!self.copiedTranslation) {
        yield self.saveMissing();
      }
    }),
    findTranslationInOtherNamespaces: flow(function* findTranslationInOtherNamespaces() {
      yield i18n.loadNamespaces(namespaces);
      const found = namespaces
        .map(ns => ({ ns, labelWithNs: `${ns}:${self.label}`, label: self.label }))
        .filter(translation => i18n.exists(translation.labelWithNs))
        .map(translation => {
          const value = i18n.t(translation.labelWithNs);
          return FoundNamespace.create({
            ...translation,
            value
          });
        });
      self.otherNamespaces.replace(found);
    }),
    selectTranslationToCopy(translationIndex: any) {
      if (translationIndex === '') {
        self.copiedTranslation = undefined;
        return;
      }

      self.copiedTranslation = clone(self.otherNamespaces[translationIndex]);
    }
  }))
  .named('MissingTranslation');

export type MissingTranslation = Instance<typeof MissingTranslation>;
